"use client"

import { getImageSrcset } from "@/lib/optimizeImage"
import { cn } from "@/lib/utils"
import { useContext, useEffect, useRef, useState, useTransition } from "react"
import { srcSets } from "@/lib/srcsets"
import { CSSProperties } from "react";
import { motion } from "framer-motion"

export default function OptimizedImage({ src, alt, className, objectFit, width, height, sizes, style, setLoaded }: { src: string, alt: string, className?: string, objectFit?: "contain" | "cover", width?: string, height?: string, sizes?: string, style?: CSSProperties, setLoaded?: (loaded: boolean) => void }) {
    // const [srcSet, setSrcSet] = useState<Awaited<ReturnType<typeof getImageSrcset>>>()
    // const [isPending, startTransition] = useTransition()
    //
    // useEffect(() => {
    //     startTransition(async () => {
    //         const res = await getImageSrcset(src, sizes)
    //         // console.log("res: ", res)
    //         if (!!res) {
    //             setSrcSet(res)
    //         }
    //     })
    // }, [])
    //
    //
    // // const srcSet = await getImageSrcset(src, sizes)
    // if (!srcSet) {
    //     return (
    //         <div className={cn("relative w-full overflow-hidden", className)}
    //             style={{
    //                 width: width,
    //                 height: height
    //             }} />
    //     )
    // }

    // @ts-ignore
    const srcSet = srcSets[src]

    const imgRef = useRef<HTMLImageElement>(null)

    function handleLoad() {
        if (imgRef.current?.complete && !!setLoaded) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        handleLoad()
    }, [imgRef.current?.complete])

    return (
        <motion.div className={cn("relative w-full h-full overflow-hidden", className)} style={style}>
            <picture
                className="">
                <source srcSet={srcSet.webp} sizes={sizes} type="image/webp" />
                <source srcSet={srcSet.fallback} sizes={sizes} type={`image/${srcSet.format}`} />
                <img
                    onLoad={handleLoad}
                    ref={imgRef}
                    className="w-full h-full max-h-full max-w-full m-auto"
                    draggable={false}
                    src={src}
                    srcSet={srcSet.fallback}
                    sizes={sizes}
                    alt={alt}
                    style={{ objectFit: objectFit || "contain" }}
                    width={width}
                    height={height}
                />
            </picture>
        </motion.div>
    )
}
